<template>
    <pop-up background-color="rgba(0, 0, 0, 0.7)" width="900px" cardColor="#ffffffea">
        <div class="main">
            <div class="title">AE通報資訊</div>
            <div class="content-text">
                本平台原非用於通報不良事件（副作用），但您可透過網站(<a
                    href="https://www.roche.com/solutions/pharma/safety-reporting"
                    target="_blank"
                    >https://www.roche.com/solutions/pharma/safety-reporting</a
                >)，向台灣羅氏藥品安全部門通報不良事件，或前往全國藥物不良反應系統網站(<a
                    href="https://adr.fda.gov.tw/"
                    target="_blank"
                    >https://adr.fda.gov.tw/</a
                >)進行通報。
                本網站所載之產品資訊乃供廣泛使用者使用或瀏覽，其中某些產品詳情或資料可能於
                閣下所處的國家無法得到或爲無效。 請注意，若獲取該等資料不符合
                閣下所處的國家的法律程式、規定、註冊或使用，本公司不會承擔任何責任。
                備註：本聲明有英文版本。如果在中文及英文版本存有歧義，以英文版本為準
            </div>
            <button-wrapper type="navigation" color="primary" @click="onConfirm">
                我瞭解了
            </button-wrapper>
        </div>
    </pop-up>
</template>

<script>
import PopUp from '@/components/Popup/PopUpWrapper.vue';
import { mapActions, mapMutations } from 'vuex';

export default {
    components: { PopUp },
    props: {
        img: String,
        fileName: String
    },

    data() {
        return {
            imgUrl:
                'https://norserium.github.io/vue-advanced-cropper/assets/img/photo.59242653.jpeg',
            imgDataUrl: undefined,
            canvasEl: undefined
        };
    },
    methods: {
        ...mapMutations({
            updateAEDisclaimerPopUpState: 'popup/updateAEDisclaimerPopUpState'
        }),
        ...mapActions({
            apiClient: 'api/invoke'
        }),
        onConfirm() {
            this.updateAEDisclaimerPopUpState(false);
        }
    }
};
</script>

<style lang="scss" scoped>
.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (min-width: 640px) {
        margin-top: 55px;
        margin-bottom: 40px;
    }
    .title {
        color: #000000;
        font-size: 20px;
        text-align: center;
        margin-bottom: 25px;
    }
    .content-text {
        white-space: normal;
        font-size: 16px;
        margin-bottom: 50px;
        @media screen and (min-width: 640px) {
            margin-bottom: 200px;
            padding: 60px;
        }
    }
}
</style>
